import request from "@/request/index";

export const dowyin_zp = (params) => {
    return request({
        url: "/api/xilujob.job/dowyin_zp",
        method: "get",  
        params,
    });
};

export const login_info = (params) => {
    return request({
        url: "/api/user/login_info",
        method: "get",  
        params,
    });
};

export const list = (params) => {
    return request({
        url: "/api/xilujob.job/bossyzp_shop",
        method: "get",  
        params,
    });
};

export const dowyin = (params) => {
    return request({
        url: "/api/xilujob.job/dowyin_zpzbj",
        method: "get",  
        params,
    });
};

export const detail = (params) => {
    return request({
        url: "/api/xilujob.job/detail",
        method: "get",  
        params,
    });
};

export const job_call = (data) => {
    return request({
        url: "/api/xilujob.job/job_call",
        method: "post",  
        data,
    });
};

export const send = (data) => {
    return request({
        url: "/api/sms/send",
        method: "post",  
        data,
    });
};

export const education_levels = (params) => {
    return request({
        url: "/api/xilujob.common/education_levels",
        method: "get",  
        params,
    });
};

export const work_experience = (params) => {
    return request({
        url: "/api/xilujob.common/work_experience",
        method: "get",  
        params,
    });
};

export const work_states = (params) => {
    return request({
        url: "/api/xilujob.common/work_states",
        method: "get",  
        params,
    });
};

export const save_module = (data) => {
    return request({
        url: "/api/xilujob.user/save_module",
        method: "post",  
        data,
    });
};

export const apply = (data) => {
    return request({
        url: "/api/xilujob.job/douyin_apply",
        method: "post",  
        data,
    });
};

export const job_categories = (params) => {
    return request({
        url: "/api/xilujob.common/job_categories",
        method: "get",  
        params,
    });
};

export const salary_types = (params) => {
    return request({
        url: "/api/xilujob.common/salary/type/3",
        method: "get",  
        params,
    });
};

export const industry_categories = (params) => {
    return request({
        url: "/api/xilujob.common/industry_categories",
        method: "get",  
        params,
    });
};

export const province_citys = (params) => {
    return request({
        url: "/api/xilujob.common/province_city",
        method: "get",  
        params,
    });
};

export const my_resume = (params) => {
    return request({
        url: "/api/xilujob.user/my_resume",
        method: "get",  
        params,
    });
};

export const mobilelogin = (data) => {
    return request({
        url: "/api/user/mobilelogin",
        method: "post",  
        data,
    });
};

export const apply_list = (params) => {
    return request({
        url: "/api/xilujob.job_apply/apply_list",
        method: "get",  
        params,
    });
};

export const zph_new = (params) => {
    return request({
        url: "/api/xilujob.news/zph_new",
        method: "get",  
        params,
    });
};

export const zph_job = (params) => {
    return request({
        url: "/api/xilujob.news/zph_job",
        method: "get",  
        params,
    });
};

export const wodetail = (params) => {
    return request({
        url: "/api/xilujob.news/detail/id/260",
        method: "get",  
        params,
    });
};

export const zphcount = (params) => {
    return request({
        url: "/api/xilujob.news/zphcount",
        method: "get",  
        params,
    });
};